@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,500&display=swap");
@import-normalize;

html {
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0 !important;
  padding: 0 !important;
}
$primary-color: #0e3fef;
.designer {
  font-size: 2em;
  font-weight: 300;
}
.portfolio-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  max-width: 1200px;
  width: 100%;
  margin: 120px auto;
  a {
    overflow: hidden;
    text-decoration: none;
    box-shadow: 0px 0px 54px #2f82ff1c;

    border-radius: 10px;
  }

  .grid-item {
    height: 250px;
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;

    .item-details {
      opacity: 0;
      padding: 24px;
      transition: all 0.2s ease;
      color: #fff;
      height: inherit;
      text-align: initial;
      font-size: 25px;
      font-weight: bold;
    }
    .subheading {
      font-size: 16px;
      font-weight: normal;
    }
    &:hover {
      transition: all 0.2s ease;
      background-color: #0e3fefb3;
      .item-details {
        opacity: 1;
        background-color: #0e3fefd1;
      }
    }
  }
}
h1 {
  font-weight: 300;
}
.close-project {
  position: fixed;
  font-size: 20px;
  z-index: 3;
  font-weight: 100;
  top: 70px;
  right: 0px;
  &:hover {
    i {
      color: $primary-color;
    }
  }
  i {
    padding: 20px;
    font-size: 40px;
    color: black;
    transition: all 0.2s ease;
  }
}
.scroll-top {
  position: fixed;
  font-size: 20px;
  z-index: 3;
  font-weight: 100;
  bottom: 20px;
  right: 0px;
  cursor: pointer;
  &:hover {
    i {
      color: $primary-color;
    }
  }
  i {
    padding: 20px;
    font-size: 40px;
    transition: all 0.2s ease;
    color: black;
  }
}
.ent {
  font-size: 2em;
  font-weight: 300;
  margin: 0 0 40px;
}
.Typewriter {
  margin: 0 0 20px 0;
  display: inline;
  font-weight: 300;
}
.Typewriter__wrapper,
.Typewriter__cursor {
  color: #ff294e;
  font-size: 2em;
}
.topMain {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 80px auto auto;
  line-height: 43px;
  h1 {
    margin: 80px 0 0 0;
  }
}

.home-container {
  text-align: center;
}

.navigation {
  display: flex;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  box-shadow: 0px 2px 20px #2f82ff0f;
  padding: 14px 0;
  margin: 0;
  z-index: 2;
  font-weight: 400;
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  li {
    padding: 0 20px;
    a {
      color: #000;
      opacity: 0.7;
      font-size: 19px;
      text-decoration: none;
      transition: all 0.2s ease;
      &.selected {
        opacity: 1;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
}
p {
  font-weight: 300;
  line-height: 24px;
}
body {
  margin: 0;
}
button {
  font-family: inherit;
  font-weight: inherit;
  &.primary {
    border: 1px solid $primary-color;
    border-radius: 5px;
    background: #fff;
    padding: 10px 30px;
    color: $primary-color;
    font-size: 17px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
      color: #fff;
      background: $primary-color;
    }
  }
  &.--home {
    margin: 80px 0;
  }
}
img {
  &.project-img {
    width: 100%;
    display: flex;
  }
}
//ABOUT PAGE
.carousel .control-dots .dot{
  width:20px !important;
  height:20px !important;
  background: #A6B8F5 !important;
  &.selected{
    background: #3A61E9 !important;
  }
}
.slide-header{
  margin: 140px auto 50px;
  max-width: 1200px;
  text-align: left;
  font-weight: 700;
}

.ikon{
  height: 24px;
  width: 24px !important;
  margin: 0 8px 0 0;
}
.about-slide{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 1200px;
  margin: 120px auto;
  width: calc(100% - 24px);
  padding: 0 ;
  &.--two{
    grid-template-columns: 1fr 1fr;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* space between columns */
    margin-top: 20px;
  }
  
  .grid-item {
    display: flex;
    text-align: left;

    flex-direction: column;
  }
  
  .accenture h2,
  .mentorship h2,
  .fun h2 {
    
    margin: 0;
    margin-bottom: 20px;
    color: #000; /* title color */
  }
  
  h3 {
    color: black; /* subtitle color */
  }
  
  ul {
    list-style-type: disc;
    padding: 0;
  }
  
  li {
    margin: 19px 0;
    font-weight: 300;

  }
  
  /* Responsive layout for smaller screens */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
}
.about {
  display: grid;
  grid-template-columns: 3fr 6fr;
  grid-gap: 40px;
  max-width: 900px;
  margin: 140px auto 0;
  width: calc(100% - 24px);
  padding: 0 12px;
  img {
    // border-radius: 100%;
    width: 100%;
  }
}

.about-content {
  text-align: justify;

  h1 {
    margin: 16px auto 37px;
    font-weight: 700;
  }
  a {
    text-decoration: none;
  }
  p {
    padding: 0 20px 0 0;
  }
}

.about-social {
  margin: 40px 0;
}

.social-icon {
  border: 1px solid $primary-color;
  border-radius: 5px;
  color: $primary-color;
  display: inline-flex;
  width: 30px;
  height: 30px;
  text-align: center;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin: 0 15px 0 0px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: $primary-color;
  }
}

.project-details {
  margin: 20px auto;
}
.download {
  margin: 35px auto 0;
  display: block;
}
.blob-1 {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 400px;
  left: 200px;
}
.blob-2 {
  width: 200px;
  position: absolute;
  top: 400px;
  right: 300px;
  height: 300px;
}
.blob-2 {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 400px;
}
.about-image{
  border-radius:100%;
  width:300px;
  height:300px;
  margin-top:22px;
}
@media screen and (max-width: 1240px) {
  .portfolio-grid-container {
    width: calc(100% - 60px);
    max-width: 800px;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  }
  .grid-item {
    background-position: center;
  }
}
@media screen and (max-width: 960px) {
}
@media screen and (max-width: 500px) {
  .topMain h1 {
    margin: 15px 15px 0 15px;
  }
  .about-image {
    display: none;
  }
  .close-project i,
  .scroll-top i {
    font-size: 33px;
  }

  .about {
    grid-template-columns: 1fr;
    margin:70px auto;
    h1,
    .about-resume,
    .about-social {
      text-align: center;
    }
    .download {
      display: flex;
      width: 100%;
      align-content: center;
      justify-content: center;
    }
    .social-icon {
      margin: 0 14px;
    }
  }
  .portfolio-grid-container {
    margin: 50px auto;
  }
}
